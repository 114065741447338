import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import CardList from '../../components/CardList'
import Accordion from '../../components/Accordion'
import { seoQuestionArray, seoPlanArray,  } from '../../components/Data'
import seoCover from '../../images/seo-cover.jpg'

import {
    CurrencyDollarIcon,
    CursorClickIcon,
    GlobeAltIcon,
    UserGroupIcon,
  } from '@heroicons/react/outline'

export default function Seo() {
    const pageAlias = 'service-seo'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/seo` });
      return (
    <DocumentMeta {...meta}>
        <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
            <img className="absolute bg-black inset-0 w-full h-full object-cover -z-10"
                src={seoCover}
                />
            <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center text-white font-bold wow fadeIn'>
                <h1 className='mb-8 text-5xl'>SEO優化計劃</h1>
                <p className='leading-[35px] text-lg'>香港有不少公司定價SEO優化服務在HK$3,000至$10,000 (月費) 的範圍，原因是要取決於不同行業和關鍵字的競爭程度。我們絕無隱藏收費，並確保排名達標。目前價目表只適用於香港地區，我們提供免費報價咨詢，立即聯絡我們，選擇適合您的SEO方案！</p>
            </div>
        </section>
        <section className='bg-transparent md:-mt-[500px]'>
                <CardList planList={seoPlanArray}/>
        </section>
        <section className='py-[70px]'>
            <div className='section-container'>
                <div className='text-center'>
                    <h2 className='mb-[10px] text-4xl'>SEO服務好處</h2>
                </div>
                <div className='grid md:grid-cols-2 gap-x-[10px] gap-y-[50px] max-w-[1200px] mx-auto mt-[80px]'>
                    <div className='flex'>
                    <div className='ml-4'>
                            <GlobeAltIcon className='w-12'/>
                        </div>
                        <div className='ml-[30px]'>
                            <h3 className='text-lg md:text-2xl mb-2'>搜尋排名第一版</h3>
                            <p className='text-base md:text-lg'>開拓網上生意版圖</p>
                        </div>
                    </div>
                    <div className='flex'>
                    <div className='ml-4'>
                            <UserGroupIcon className='w-12'/>
                        </div>
                        <div className='ml-[30px]'>
                            <h3 className='text-lg md:text-2xl mb-2'>發掘潛在用戶</h3>
                            <p className='text-base md:text-lg'>增加收入來源</p>
                        </div>
                    </div>
                    <div className='flex'>
                    <div className='ml-4'>
                            <CursorClickIcon className='w-12'/>
                        </div>
                        <div className='ml-[30px]'>
                            <h3 className='text-lg md:text-2xl mb-2'>美化網站搜尋外觀</h3>
                            <p className='text-base md:text-lg'>提升品牌形象</p>
                        </div>
                    </div>
                    <div className='flex'>
                    <div className='ml-4'>
                            <CurrencyDollarIcon className='w-12'/>
                        </div>
                        <div className='ml-[30px]'>
                            <h3 className='text-lg md:text-2xl mb-2'>節省投放於SEM的金錢成本</h3>
                            <p className='text-base md:text-lg'>SEO優化長期有效</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section className='bg-theme-color-5 py-[70px]'>
            <div className='section-container max-w-[1300px]'>
            <h2 className='text-4xl mb-[50px] text-white text-center'>SEO優化計劃 FAQ</h2>
                {seoQuestionArray.map(({ title, content }) => (
                    <Accordion key={title} title={title} content={content} />
                ))}
            </div>
        </section>
    </DocumentMeta>
  )
}
