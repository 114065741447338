import yifunghkThumbnail from '../images/yifunghk.webp'
import chilokhkThumbnail from '../images/chilokhk.webp'
import drcredithkThumbnail from '../images/drcredithk.webp'

import {
  BriefcaseIcon,
  CursorArrowRaysIcon,
  ComputerDesktopIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
  PhoneIcon,
  GlobeAltIcon,
  SquaresPlusIcon,
  ChartPieIcon,
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
  SignalIcon,
  MusicalNoteIcon,
  BookOpenIcon,
  NewspaperIcon,
  SpeakerWaveIcon,
  ServerIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";

export const mobileMenuItems = [
  {
    title: "最新手機資訊",
    description: "找到最新手機的完美解決方案。",
    icon: GlobeAltIcon,
    link: '/mobile',
  },
  /*
  {
    title: "手機比較",
    description: "了解各款手機的特色和規格。",
    icon: ChartPieIcon,
    link: '/mobile/comparison',
  },
  */
  {
    title: "手機品牌",
    description: "探索各大手機品牌的資訊。",
    icon: DevicePhoneMobileIcon,
    link: '/mobile/brand',
  },
  {
    title: "手機程式推介",
    description: "選擇最適合您需求的手機程式。",
    icon: WrenchScrewdriverIcon,
    link: '/mobile/recommend',
  },
  {
    title: "手機評測",
    description: "詳細手機評測及介紹。",
    icon: NewspaperIcon,
    link: '/mobile/blog',
  },
  {
    title: "熱門APP推介",
    description: "發掘熱門的APP推薦。",
    icon: SquaresPlusIcon,
    link: '/mobile/recommend/apps',
  },
  {
    title: "熱門Podcast推介",
    description: "聆聽熱門手機Podcast節目的推薦。",
    icon: SignalIcon,
    link: '/mobile/recommend/podcasts',
  },
  {
    title: "熱門Apple Music推介",
    description: "發掘熱門的Apple Music推薦。",
    icon: MusicalNoteIcon,
    link: '/mobile/recommend/music',
  },
  {
    title: "熱門Apple Book推介",
    description: "發掘熱門的Apple Book推薦。",
    icon: BookOpenIcon,
    link: '/mobile/recommend/books',
  },
  /*
  {
    title: "熱門Apple Audio Book推介",
    description: "發掘熱門的Apple Audio Book推薦。",
    icon: SpeakerWaveIcon,
    link: '/mobile/recommend/audio-books',
  }
  */
];

export const tabletMenuItems = [
  {
    title: "最新平板電腦資訊",
    description: "找到最新平板電腦的完美解決方案。",
    icon: GlobeAltIcon,
    link: '/tablet',
  },
  {
    title: "平板電腦比較",
    description: "比較各款平板電腦的特色和規格。",
    icon: ChartPieIcon,
    link: '/tablet/comparison',
  },
  {
    title: "平板電腦品牌",
    description: "探索各大平板電腦品牌的資訊。",
    icon: DeviceTabletIcon,
    link: '/tablet/brand',
  },
];

export const aboutusMenuItems = [
  {
    title: "網頁開發",
    description: "提供網頁開發服務，讓您的網站更具吸引力。",
    icon: ServerIcon,
    link: '/service',
  },
  /*
  {
    title: "聯絡我們",
    description: "聯絡Digital Tech IN ONE。",
    icon: PhoneIcon,
    link: '/aboutus',
  },
  {
    title: "合作機會",
    description: "尋找與我們合作的機會。",
    icon: UsersIcon,
    link: '/cooperate',
  },
  */
];

export const formQuestions = [
  {
    htmlTag: "input",
    rows: "",
    type: "text",
    placeholder: "稱呼 Name",
    name: "name",
    validations: { required: true },
    errorMessages: { required: "請填寫此欄" }
  },
  {
    htmlTag: "input",
    rows: "",
    type: "email",
    placeholder: "電郵地址 Email Address",
    name: "email",
    validations: { required: true, pattern: /^\S+@\S+$/i },
    errorMessages: { required: "請填寫此欄", pattern: "請填寫正確的電郵格式" },
  },
  {
    htmlTag: "input",
    rows: "",
    type: "tel",
    placeholder: "聯絡電話 Phone Number",
    name: "phone",
    validations: { required: true, pattern: /[0-9]/ },
    errorMessages: { required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需數字)" },
  },
  {
    htmlTag: "textarea",
    rows: "6",
    type: "message",
    placeholder: "留言 Your Message",
    name: "message",
    validations: { required: true, maxLength: 200 },
    errorMessages: { required: "請填寫此欄", maxLength: "字數上限為200字" },
  }
]

export const commonInfo =
{
  phone: "85266702192",
  email: "contactus@digitechinone.com"
}

export const commonServices = [
  {
    alias: 'web-development',
    name: '網頁設計',
    abstract: '製定合適的網頁設計方案',
    description: '為您度身製作網站，製定合適的網頁設計方案，重視使用者體驗及視覺設計。無論是公司網頁、網上商店、個人化網頁等，都會針對客戶要求設計。',
    href: '/service/web-design',
    icon: ComputerDesktopIcon,
  },
  {
    alias: 'seo',
    name: 'SEO優化',
    abstract: '提昇網站SEO排名',
    description: '幫助提昇網站SEO排名，了解搜尋引擎的自然排名運作原理來優化網站。有效地改善您的網站以獲得更多的瀏覽量，產生更多流量，接觸更多潛在客戶。',
    href: '/service/seo',
    icon: CursorArrowRaysIcon,
  },
  {
    alias: 'digital-marketing',
    name: '數碼營銷',
    abstract: "一站式全面數碼行銷方案",
    description: "一站式全面數碼行銷方案，為各行各業的客戶策劃不同類型的營銷計劃如搜尋引擎推廣(Google Ads)及內容行銷(Content Marketing)等，提升網站曝光率。",
    href: '/service/digital-marketing',
    icon: BriefcaseIcon
  },
]

export const commonContact = [
  { name: '聯絡客務：' + commonInfo.phone, href: '#', icon: PhoneIcon },
  { name: '電郵：' + commonInfo.email, href: '#', icon: EnvelopeIcon },
]

export const seoQuestionArray = [
  {
    title: "SEO是甚麼？",
    content: "SEO是透過了解搜尋引擎的運作原理後，對網站作出內容和程式碼上的調整，以提升網站的搜尋排名，增加曝光度。"

  },
  {
    title: "SEO和SEM有什麼差別？",
    content: "SEM優點是投入多少費用，就能立即取得對應的流量，但一旦停止投入金錢，便沒有功效；相反，SEO能夠相對性地長期保持網站的搜尋排名。"
  },
  {
    title: "沒有網站的話，但想發展網上生意，有其他服務可以幫助得到嗎？",
    content: "沒有問題，我們亦有數碼營銷計劃，並有顧問會針對您的需求與預算為您規劃合適的方案。例如您可以使用Facebook或Instagram發展網上生意，再交由我們為您發掘潛在用戶。如果你認為社交平台並不是合適的途徑，可參考我們的網頁開發計劃，為您量身訂造整個網站，更有效地發展您的網上生意。"
  },
];
export const webdevelopmentQuestionArray = [
  {
    title: "一站式服務提供甚麼？",
    content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"

  },
  {
    title: "為何要申請網域名稱？",
    content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
  },
  {
    title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
    content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
  },
];
export const designQuestionArray = [
  {
    title: "我可以購買單一項目？",
    content: "可以，我們以上提及的項目都能夠單一購買。如 閣下有其他設計項目，歡迎查詢。"

  }
];
export const digitalMarketingQuestionArray = [
  {
    title: "數碼營銷是甚麼？",
    content: "數碼營銷是指針對使用數碼裝置的用戶作出行銷。透過數位渠道對消費者推廣產品和服務，不需要有實體上的互動都能有效推銷及吸引用戶。"
  },
  {
    title: "數碼營銷有甚麼分類？",
    content: "數碼營銷種類繁多，包括社交媒體營銷、搜尋引擎行銷(SEM)、搜尋引擎優化(SEO)、電子郵件營銷等。由於太多分類，以上3個計劃中未有涵蓋所有數碼營銷手法， 閣下有相關需要都可以免費向我們查詢及索取報價。"
  },
];


export const DigitalMarketingSwiperList = [
  {
    thumbnail: yifunghkThumbnail,
    heading: "網頁開發 - 建築材料公司"

  },
  {
    thumbnail: chilokhkThumbnail,
    heading: "網頁開發 - 按揭公司"

  },
  {
    thumbnail: drcredithkThumbnail,
    heading: "SEO優化 - 財務公司"

  }

];

export const MetaDataList = {
  home: {
    page: 'home',
    title: '香港網頁製作公司',
    description: "香港具規模的網站開發公司，專業團隊，提供一站式的網站設計與製作服務，滿足各種企業的不同建站需求",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁, 數碼營銷, SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化"
  },
  seo: {
    page: 'seo',
    title: '搜尋引擎優化(SEO)服務',
    description: "透過了解搜尋引擎的運作原理後，對網站作出內容和程式碼上的調整，以提升網站的搜尋排名，增加曝光度。",
    keyword: "SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化",
  },
  digital: {
    page: 'digital-marketing',
    title: '數碼營銷服務',
    description: "數碼營銷有多種不同方法，透過網上宣傳如SEM、社交媒體廣告等，尋找目標客戶，增加網店銷售額。",
    keyword: "數碼營銷, digital marketing",
  },
  develop: {
    page: 'web-development',
    title: '網頁開發服務',
    description: "我們提供專業的網頁開發服務，了解您的需求，由豐富經驗的網頁開發人員為你服務。製作專屬於您的網站，響應式網頁設計令用戶不論以任何電子設備瀏覽網頁都能以最好介面示人。",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁",
  },
  design: {
    page: 'design',
    title: '設計服務',
    description: "反映出公司風格的設計，往往能令客戶有深刻的印象。除了網頁設計外，公司標誌、卡片、廣告、EDM等，都是我們服務範圍。透過獨特嶄的設計，吸引目標客戶的注意，令您公司的形象深深刻在他們的腦海中！",
    keyword: "書刊設計,小册子設計, 菜單設計, 商標設計, 橫額設計, 咭片設計, 信封設計, 錦旗設計, 廣告設計, 海報宣傳設計, 單張設計, 坐枱月曆設計",
  },
  contact: {
    page: 'contact',
    title: '聯絡我們',
    description: "任何網頁開發、設計相關、數碼營銷和SEO優化的項目，歡迎直接聯絡我們。我們誠心幫助每一個客戶拓展業務，提供不同種類的技術支援。",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁, 數碼營銷, SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化"
  },
  error: {
    title: '頁面不存在',
    description: "這是404頁面。 您已被重定向至此處，因為您請求的頁面不存在！"
  }
}

export const seoPlanArray = [
  {
    title: '低競爭度SEO優化',
    price: '3,500',
    period: '/起*每月',
    description: [
      { '關鍵字數量': '25' },
      { '合約期': '3個月' },
      { '競爭程度': '低' },
      { '關鍵字分析': '✔️' },
      { '全面站內優化': '✔️' },
      { '內容優化': '✔️' },
      { '第一頁排名保證': '✔️' },
      { '每月排名報表': '✔️' },
    ],

  },
  {
    title: '中競爭度SEO優化',
    price: '6,000',
    period: '/起*每月',
    description: [
      { '關鍵字數量': '25' },
      { '合約期': '6個月' },
      { '競爭程度': '低' },
      { '關鍵字分析': '✔️' },
      { '全面站內優化': '✔️' },
      { '內容優化': '✔️' },
      { '第一頁排名保證': '✔️' },
      { '每月排名報表': '✔️' },
      { 'SEO文章撰寫': '✔️' },
    ],

  },
  {
    title: '高競爭度SEO優化',
    price: '8,000',
    period: '/起*每月',
    description: [
      { '關鍵字數量': '25' },
      { '合約期': '12個月' },
      { '競爭程度': '低' },
      { '關鍵字分析': '✔️' },
      { '全面站內優化': '✔️' },
      { '內容優化': '✔️' },
      { '第一頁排名保證': '✔️' },
      { '每月排名報表': '✔️' },
      { 'SEO文章撰寫': '✔️' },
      { '網頁外觀設計': '✔️' },
    ],

  },
]

export const designPlanArray = [
  {
    title: '排版設計',
    price: '1000',
    period: '/起*每個項目最低價',
    description: [
      { '書刊': '✔️' },
      { '小册子': '✔️' },
      { '菜單': '✔️' },
    ],

  },
  {
    title: '品牌設計',
    price: '1000',
    period: '/起*每個項目最低價',
    description: [
      { '商標': '✔️' },
      { '橫額': '✔️' },
      { '咭片(雙面)': '✔️' },
      { '信封': '✔️' },
      { '錦旗': '✔️' },

    ],

  },
  {
    title: '廣告宣傳設計',
    price: '1500',
    period: '/起*每個項目最低價',
    description: [
      { '網絡廣告': '✔️' },
      { '海報': '✔️' },
      { '宣傳單張': '✔️' },
      { '坐枱月曆': '✔️' },
    ],
  },
]

export const webDevelopmentPlanArray = [
  {
    title: '網頁開發(不包括後台管理系統)',
    price: '3,000',
    period: '起',
    description: [
      { '1至10頁專業網站版面': '✔️' },
      { '響應式網頁設計': '✔️' },
      { '網頁動態設計': '✔️' },
      { '網域申請': '✔️' },
      { '免費伺服器': '✔️' },
      { '免費伺服器': '✔️' },
    ],

  },
  {
    title: '網頁開發(包括後台管理系統)',
    price: '6,500',
    period: '起',
    description: [
      { '1至10頁專業網站版面': '✔️' },
      { '響應式網頁設計': '✔️' },
      { '網頁動態設計': '✔️' },
      { '網域申請': '✔️' },
      { '免費伺服器': '✔️' },
      { '特定後台管理系統': '✔️' },
      { '電郵提示': '✔️' },
    ],

  },
  {
    title: '度身訂製',
    price: '商議',
    period: '',
    description: [
      { '專業網站版面': '商議' },
      { '響應式網頁設計': '✔️' },
      { '網頁動態設計': '✔️' },
      { '網域申請': '✔️' },
      { '免費伺服器': '✔️' },
      { '訂製後台管理系統': '✔️' },
      { '電郵提示': '✔️' },
    ],
  },
]
export const digitalMarketingPlanArray = [
  {
    title: 'Facebook & Instagram Management',
    price: '4,500',
    period: '/起*每月',
    description: [
      { '每月總共貼文': '4篇' },
      { '每月廣告費用': '自行決定 (min: HKD$3,000)' },
      { '廣告費用手續費': '20%' },
      { '報告分析': '✔️' },
      { '合約期': '3月起' },
    ],

  },
  {
    title: 'Facebook, Instagram, Youtube Management & Google Ads',
    price: '7,000',
    period: '/起*每月',
    description: [
      { '每月總共貼文': '4篇' },
      { '每月廣告費用': '自行決定 (min: HKD$3,000)' },
      { '廣告費用手續費': '免手續費' },
      { '報告分析': '✔️' },
      { '關鍵字分析': '✔️' },
      { '每月排名分析': '✔️' },
      { '合約期': '2個月起' },
    ],

  },
  {
    title: 'Facebook, Instagram & Youtube Management',
    price: '6,000',
    period: '/起*每月',
    description: [
      { '每月總共貼文': '4篇' },
      { '每月廣告費用': '自行決定 (min: HKD$3,000)' },
      { '廣告費用手續費': '20%' },
      { '報告分析': '✔️' },
      { '關鍵字分析': '✔️' },
      { '每月排名分析': '✔️' },
      { '合約期': '3個月起' },
    ],
  },
]
export const basicSpecificationArray = {
  'phone_brand': '品牌',
  'announce_date': '發表日期',
  'release_date': '推出日期',
  'price': '價格',
  'form_factor': '型狀',
  'network': '網絡系統',
  'cpu': '中央處理器',
  'gpu': '圖像處理器',
  'os': '操作系統及版本',
  'color': '機身顏色',
  'dimensions': '尺寸',
  'weight': '重量',
  'navigation': '操控',
  'fingerprint': '指紋功能',
  'battery_type': '電池種類',
  'removeable_battery': '可更換電池',
  'talk_time': '通話時間',
  'standby_time': '備用時間',
  'ram': 'RAM 容量',
  'rom': 'ROM 容量',
  'expandable_memory': '可擴充記憶',
  'max_memory_size': '最大支援記憶體',
}

export const monitorSpecificationArray = {
  'screen_size': '螢幕尺寸',
  'screen_type': '螢幕類別',
  'screen_resolution': '螢幕解像度',
  'screen_remark': '螢幕備註',
}


export const cameraSpecificationArray = {
  'camera': '數碼相機',
  'pixels': '像素',
  'autofocus': '自動對焦',
  'optical_stablizer': '光學防震',
  'optical_zoom': '光學變焦',
  'digital_zoom': '數碼變焦',
  'conference_cam': '視像鏡頭',
  'flash': '閃光燈',
  'other_cameras_features': '其他相機功能',
  'video': '短片拍攝',
  'video_resolution': '短片解像度',
  'video_zoom': '短片變焦',
}

export const multimediaSpecificationArray = {
  'music_player': '音樂播放格式',
  'video_player': '短片播放格式',
  'radio_player': '收音機',
  'streaming': '串流播放格式',
  'voice_command': '聲控功能',
  'incoming_alert': '來電提示',
  'application_download': 'Apps 下載',
}

export const connectivitySpecificationArray = {
  'nfc': 'NFC',
  'bluetooth': '藍牙支援',
  'infrared': '紅外線支援',
  'mobile_data': '流動數據',
  'wifi': 'wifi 功能',
  'gps': 'gps 功能',
  'pc': '電腦連線',
  'tvout': '視頻輸出',
}
export const mobileBrandArray = {
  "apple": "Apple",
  "samsung": "Samsung",
  "sony": "Sony",
  "microsoft": "Microsoft",
  "nokia": "Nokia",
  "google": "Google",
  "xiaomi": "Xiaomi",
  "huawei": "Huawei",
  "oppo": "OPPO",
  "vivo": "Vivo",
  "oneplus": "OnePlus",
  "asus": "ASUS",
  "lg": "LG",
  "htc": "HTC",
  "motorola": "Motorola",
  "blackberry": "BlackBerry",
  "lenovo": "Lenovo",
  "alcatel": "Alcatel",
  "acer": "Acer",
  "tcl": "TCL",
  "meizu": "Meizu",
  "sony-ericsson": "Sony Ericsson",
  "sharp": "Sharp",
  "toshiba": "Toshiba",
  "panasonic": "Panasonic",
  "zte": "ZTE",
  "hp": "HP",
  "dell": "Dell",
  "nec": "NEC",
  "palm": "Palm",
  "vertu": "Vertu",
  "razer": "Razer",
  "vodafone": "Vodafone",
  "caterpillar": "Caterpillar",
  "meitu": "Meitu",
  "honor": "HONOR",
  "inq": "INQ",
  "jolla": "Jolla",
  "k-touch": "K-Touch",
  "kodak": "Kodak",
  "ulefone": "Ulefone",
  "sugar": "Sugar",
  "smartisan": "Smartisan",
  "infocus": "InFocus",
  "gsmart": "GSmart",
  "freetel": "Freetel",
  "onkyo": "Onkyo",
  "nothing": "Nothing",
  "realme": "Realme",
  "letv": "Letv",
  "porsche-design": "Porsche Design"
};


export const tabletBrandArray = {
  "apple": "Apple",
  "samsung": "Samsung",
  "archos": "Archos",
  "honor": "HONOR",
  "huawei": "HUAWEI",
  "lenovo": "Lenovo",
  "microsoft": "Microsoft",
  "nokia": "Nokia",
  "oppo": "OPPO",
  "xiaomi": "Xiaomi",
  "acer": "Acer",
  "alcatel": "Alcatel",
  "arnova": "Arnova",
  "asus": "ASUS",
  "blackBerry": "BlackBerry",
  "dell": "Dell",
  "fujitsu": "Fujitsu",
  "google": "Google",
  "hp": "HP",
  "htc": "HTC",
  "lg": "LG",
  "motorola": "Motorola",
  "sony": "Sony",
  "toshiba": "Toshiba",
  "viewsonic": "ViewSonic",
  "zte": "ZTE",
};




export const recommendArray = {
  'music': {
    title: '熱門Apple Music推介',
    icon: MusicalNoteIcon,
    type: {
      'albums': {
        title: 'Albums',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'music-videos': {
        title: 'Music Videos',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'playlists': {
        title: 'Playlists',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'songs': {
        title: 'Songs',
        feed: {
          'most-played': 'Most Played'
        }
      },
    }

  },
  'podcasts': {
    title: '熱門Podcast推介',
    icon: SignalIcon,
    type: {
      'podcasts': {
        title: 'Podcasts',
        feed: {
          'top': 'Top',
          //'top-subscriber': 'Top Subscriber'
        }
      },
      /*
      'podcasts-episodes': {
        title: 'Podcasts Episodes',
        feed: {
          'top': 'Top',
        }
      },
      'podcasts-channels': {
        title: 'Podcasts Channels',
        feed: {
          'top-subscriber': 'Top Subscriber'
        }
      },
      */
    }
  },
  'apps': {
    title: '熱門APP推介',
    icon: SquaresPlusIcon,
    type: {
      'apps': {
        title: 'Apps',
        feed: {
          'top-free': 'Top Free',
          'top-paid': 'Top Paid'
        }
      },
    }
  },
  'books': {
    title: '熱門Apple Book推介',
    icon: BookOpenIcon,
    type: {
      'books': {
        title: 'Books',
        feed: {
          'top-free': 'Top Free',
          //'top-paid': 'Top Paid'
        }
      },
    }
  },
  /*
  'audio-books': {
    title: '熱門Apple Audio Book推介',
    icon: SpeakerWaveIcon,
    type: {
      'audio-books': {
        title: 'Audio Books',
        feed: {
          'top':'Top',
          '1355215958': 'Everyday listens for $9.99',
          '1461817928': '$9.99 Fiction & Literature',
          '1461817926': '$9.99 Mysteries & Thrillers',
          '1461817931': '$9.99 Nonfiction',
          '1461817929': '$9.99 Biographies & Memoirs',
          '1461817927': '$9.99 Business & Self-Development',
          '1461817933': '$9.99 Romance',
          '1461817932': '$9.99 Sci-Fi & Fantasy',
          '1461817930': '$9.99 Kids & Young Adults',
        }
      },
    }
  }
  */
}

export const metaTag = {
  commonTitle: 'digitechinone.com | 網頁開發及電子資訊資料庫',
  commonKeywords: 'digitechinone',
  'mobile-blog-detail': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-blog': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-brand-list': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-home': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-product-detail': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-product-detail': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-product-list': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-recommend-list': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'mobile-recommend-detail': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'tablet-home': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'tablet-brand-list': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'tablet-product-detail': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'tablet-product-list': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'service-digital-marketing': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'service-home': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'service-seo': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'service-web-design': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
  'service-web-development': {
    title: '手機資訊',
    description: '手機資訊',
    keywords: '手機資訊',
  },
}
