import React from "react";
import {
  Button,
  IconButton,
  Rating,
  Typography,
} from "@material-tailwind/react";
import { HeartIcon } from "@heroicons/react/24/outline";

export function ProductDetailSection({ deviceType, data }) {
  return (
    <section className="">
      <div className="mx-auto container grid grid-cols-1 md:grid-cols-2">
        <img
          src={`/images/${deviceType}/${data.id}.jpg`}
          alt={data.phone_name}
          className="h-[16rem] m-auto"
        />
        <div>
          <Typography className="mb-4" variant="h3">
            {data.phone_name}
          </Typography>
          <Typography variant="h5">價格: {data.price}</Typography>
          <Typography className="!mt-4 text-base font-normal leading-[27px] !text-gray-500">
            機身尺寸: {data.dimensions}<br />
            機身顏色: {data.color} <br />
            RAM 容量: {data.ram} <br />
            ROM 容量: {data.rom} <br />
            螢幕解像度: {data.screen_resolution} <br />
            螢幕尺寸: {data.screen_size} <br />
            相機像素: {data.pixels} <br />
          </Typography>


          <div className="mb-4 flex w-full items-center gap-3 md:w-1/2 ">
            <Button color="gray" className="w-52">
              與其他型號比較
            </Button>

          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductDetailSection;