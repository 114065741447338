import { db } from '../firebase';
import { doc, collection, getDoc, getDocs, collectionGroup, query, limit } from "firebase/firestore";
import client from "../client"
import { mobileBrandArray, tabletBrandArray } from '../components/Data';
const apiUrl = process.env.REACT_APP_API_URL;



export const getPostDetail = async (title) => {
  const result = client
    .fetch(
      `*[title == "${title}"] {
      title,
      description,
      brands,
      devices,
      body,
      categories[] -> {
        title,
        slug
      },
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      }
    }`
    )
  return result;
};

export const getPosts = async (catAlias) => {
  const filterCondition = (catAlias) ? `&& *[_type == "category" && slug.current == "${catAlias}"][0]._id in categories[]._ref` : ''
  const result = client
    .fetch(
      `
    *[_type=='post' ${filterCondition} ] | order(publishedAt desc){
      title,
      slug,
      brands,
      devices,
      categories[0]->{slug, title, description},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      publishedAt
    }
    `
    )
  return result;
};


export const getCategories = async () => {
  const result = client
    .fetch(
      `*[_type == "category"] {
        title,
        slug,
        alias
    }`
    )
  return result;
};


export const getLatestPosts = async (limit, isImageNeeded = false) => {
  const filterCondition = (isImageNeeded) ? "mainImage {asset -> { _id, url }, alt},publishedAt," : ""
  const result = client
    .fetch(
      `*[_type == "post"] | order(publishedAt desc) {title, ${filterCondition} categories[0]->{alias, title}} [0...${limit}]`
    )
  return result;
};

export const getRelatedPosts = async (slug, title) => {
  const randomCondition = Math.floor(Math.random() * 2);
  const randomOrder = Math.floor(Math.random() * 2);
  const condition = (randomCondition === 0) ? 'title' : 'publishedAt'
  const order = (randomOrder === 0) ? 'asc' : 'desc'
  const result = client
    .fetch(
      `
          *[_type=='post' && title != "${title}" && *[_type == "category" && slug.current == "${slug}"][0]._id in categories[]._ref] | order(${condition} ${order}){
            title,
            slug,
            categories[0]->{slug, title},
            mainImage {
              asset -> {
                _id,
                url
              },
              alt
            },
            publishedAt
          }[0..2]`
    )
  return result;
};

export const getDeviceDetail = async (deviceType, deviceBrand, deviceID) => {
  try {
    const querySnapshot = await getDoc(doc(db, 'data', deviceType, deviceBrand, deviceID));
    let data = querySnapshot.data()
    data.id = deviceID
    return data;
  } catch (error) {
    console.error("Error fetching device detail:", error);
    throw error;
  }
};
export const getDeviceListByBrand = async (deviceType, deviceBrand) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'data', deviceType, deviceBrand));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      price: doc.data().price.replace(/<br>.*/, ''),
      phone_brand: doc.data().phone_brand,
      phone_name: doc.data().phone_name,
    }));
    return data;
  } catch (error) {
    console.error("Error fetching device list:", error);
    throw error;
  }
};


export const getDeviceList = async (deviceType='mobile') => {
  try {

    const brandKeys = (deviceType == 'mobile') ? Object.keys(mobileBrandArray) : Object.keys(tabletBrandArray);

    const deviceListQueries = brandKeys.map((brandKey) => {
      return query(collection(db, 'data', deviceType, brandKey), limit(1));
    });

    const deviceListSnapshots = await Promise.all(deviceListQueries.map(getDocs));
    const data = [];

    deviceListSnapshots.forEach((snapshot, index) => {


      snapshot.forEach((doc) => {

        const { price, phone_brand, phone_name } = doc.data();

        const docObject = {
          id: doc.id,
          price: price.replace(/<br>.*/, ''),
          phone_brand,
          phone_name
        };

        data.push(docObject);
      });
    });

    return data;

  } catch (error) {
    console.error('Error fetching collections:', error);
    return [];
  }
};
// ...

export const fetchRecommendation = async (mediaType, type, feed, limit = 10) => {
  try {
    const response = await fetch(`${apiUrl}/index.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mediaType: mediaType,
        type: type,
        feed: feed,
        limit: limit,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch data`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};