import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { ProductListSection } from "../../components/ProductListSection";
import { getDeviceList } from "../../service";
export function TabletHome() {
    const [deviceListData, setDeviceListData] = useState([]);

    useEffect(() => {
        getDeviceList('tablet').then((responseData) => {
            if (responseData) {
                setDeviceListData(responseData)
            }
        });
    }, []);

    const landingArray = [
        { name: '平板資訊', landing: `/tablet` },
        { name: '最新推出平板', landing: `/tablet` },
    ];

    const pageAlias = 'tablet-home'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `tablet/` });

    return (
        <DocumentMeta {...meta}>
            <section className="mt-2 bg-white px-4  pt-4">
                <BreadcrumbsWithIcon landingArray={landingArray} />
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <ProductListSection deviceType='tablet' data={deviceListData} />
            </section>
        </DocumentMeta >
    );
}

export default TabletHome;
