import React from "react";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import { FeatureCard } from "../components/FeatureCard";
export function RecommendListSection({ data }) {
  return (
    <>
      {Object.entries(data).map(([categoryKey, categoryValue]) => (
        <section key={categoryKey} className=" mb-16 md:mb-24">
          <div className="mx-auto text-center">
            <Typography as="a" href={`/mobile/recommend/${categoryKey}`} variant="h2" className="my-12 text-2xl">
              {categoryValue.title}
            </Typography>

          </div>
          <div className="mx-auto container">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
              {Object.entries(categoryValue.type).map(([mediaKey, mediaValue]) => (
                Object.entries(mediaValue.feed).map(([feedKey, feedValue]) => (
                  <div key={`${mediaKey}-${feedKey}`}>
                    <a href={`/mobile/recommend/${categoryKey}#${mediaKey}-${feedKey}`}>
                      <div className="mx-auto text-center">
                        <Typography variant="h3" className="my-2 text-xl">
                          {mediaValue.title}
                        </Typography>
                      </div>
                      <FeatureCard
                        key={feedKey}
                        color='gray'
                        title={''}
                        icon={React.createElement(categoryValue.icon, {
                          className: "w-5 h-5 text-white bg-slate-100",
                        })}
                        description={feedValue}
                      />
                    </a>


                  </div>
                ))
              ))}
            </div>
          </div>
        </section>
      ))}
    </>
  );
}

export default RecommendListSection;