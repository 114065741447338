import { useState, useEffect } from "react"
import moment from 'moment';

export default function PostWidgets({deviceType, data}) {

  return (
    <div className="bg-white z-20 shadow-lg rounded-lg p-8 pb-12 mb-8">
      <h3 className="text-base mb-8 font-bold border-b pb-2">相關文章</h3>
      {data.map((post, index) => (
        <div key={index} className="flex items-center w-full mb-4">
          <div className="w-16 flex-none">
          <a key={post.slug} href={`/blog/${post.categories.alias}/${post.title}`} ><img src={post.mainImage.asset.url} alt={post.title} className="align-middle rounded-full" /></a>
          </div>
          <div className="flex-grow ml-4">
            <p className="text-gray-500 font-xs text-sm">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
            <a key={post.slug} href={`/${deviceType}/blog/${post.categories.slug.current}/${post.title}`} className="text-md">{post.title}</a>
          </div>
        </div>
      ))}
    </div>
  )
}
