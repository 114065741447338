import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { RecommendDetailSection } from "../../components/RecommendDetailSection";
import { fetchRecommendation } from "../../service";
import { recommendArray } from "../../components/Data";
export function MobileRecommendDetail() {
  const [recommendDetailData, setRecommendDetailData] = useState({});
  const { recommendType } = useParams();

  useEffect(() => {
    const targetType = recommendArray[recommendType].type;

    const fetchData = async () => {
      for (const typeKey in targetType) {
        if (targetType.hasOwnProperty(typeKey)) {
          const type = targetType[typeKey];
          for (const key in type.feed) {
            const responseData = await fetchRecommendation(recommendType, typeKey, key);
            if (responseData) {
              setRecommendDetailData(prevData => ({
                ...prevData,
                [typeKey+'-'+key]: responseData
              }));
            }
          }
        }
      }
    };

    fetchData();
  }, []);

  const landingArray = [
    { name: '手機資訊', landing: `/mobile` },
    { name: '手機程式推介', landing: `/mobile/recommend` },
    { name: recommendArray[recommendType].title, landing: `/mobile/recommend/${recommendType}` },
  ];
  const pageAlias = 'mobile-recommend-detail'
  const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/mobile/recommend/${recommendType}` });
  return (
    <DocumentMeta {...meta}>
      <section className="mt-2 bg-white px-4  pt-4">
        <BreadcrumbsWithIcon landingArray={landingArray} />
      </section>
      {Object.entries(recommendDetailData).map(([typeKey, typeValue]) => (
        <section className="mt-2 bg-white px-4  pt-4">
          <RecommendDetailSection dataKey={typeKey} data={typeValue} />
        </section>
      ))}

      <section className="mt-2 bg-white px-4 pt-4">
        <div className="mx-auto container grid text-center">
          <Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' />
        </div>
      </section>
    </DocumentMeta>
  );
}

export default MobileRecommendDetail;
