import React from 'react'

export default function CardList(planList) {
    return (
        <div className='md:section-container mx-auto'>
            <div className='flex-col md:flex-row flex justify-center items-center md:items-start wow fadeInUp'>
                {planList.planList.map(({ title, price, period, description }) => {
                    return (
                        <div key={title} className='mt-[30px] md:mt-0 w-full md:w-1/3 max-w-[600px] md:max-w-[34%] bg-[#f7f7f7] p-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.1)] text-center first:md:scale-95 first:md:translate-x-[30px] first:z-[10] last:md:scale-95 last:md:-translate-x-[30px] last:z-[10] z-[99]'>
                            <h5 className='text-xl'>{title}</h5>
                            <div className='relative my-[30px] w-fit mx-auto'>
                                <span className='absolute -left-8'>HK$</span>
                                <span className='text-5xl text-theme-color-1 font-bold'>{price}</span>
                                <span className='text-base'>{period}</span>
                            </div>
                            <ul className='p-[30px]'>
                                {description.map((item, index) => {
                                    return (
                                        <li key={index} className='flex p-[12px] border-b-[2px] border-[#dfdddd]'>
                                            <div className='w-2/4'>{Object.keys(item)}</div>
                                            <div className='w-2/4'>{Object.values(item)}</div>
                                        </li>
                                    )
                                })}

                            </ul>
                            <div className='relative group my-[30px] w-fit mx-auto'>
                                <a href="#" className='effect-cta-3 bg-theme-color-1  flex items-center w-fit mx-auto'>查詢更多
                                    <div className='transition-all -ml-[20px] invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:ml-0'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                        </svg>
                                    </div>
                                </a>

                            </div>

                        </div>
                    )
                })}
            </div>
            <div className='relative group my-[50px] w-fit mx-auto wow rubberBand'>
            <a href="#" className='effect-cta-3 flex items-center w-fit mx-auto bg-theme-color-1'>立即 Whatsapp 聯絡我們
                <div className='transition-all -ml-[20px] invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:ml-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </a>
            </div>
        </div>
    )
}
