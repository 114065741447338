import React from 'react'
import { MetaDataList } from '../components/Data'
import Form from '../components/Form'
import SocialMediaShare from '../components/SocialMediaShare'
import contactContent1 from '../images/contact-content1.jpg'


export default function Contact() {
  return (
    <div>

        <section className="section-container py-[70px] relative ">
            <div className='gradient-white md:hidden'> </div>
            <div className='section-container mx-auto relative '>
                <div className='block md:flex'>
                    <div className='flex-1 pb-[80px]'>
                        <h2 className='text-theme-color-2 text-3xl mb-[30px] wow fadeInLeft'>免費報價</h2>
                        <div className='wow fadeInUp'>
                            <p className='text-lg'>任何網頁開發、設計相關、數碼營銷和SEO優化的項目，歡迎直接聯絡我們。</p>
                            <p className='text-lg'>我們誠心幫助每一個客戶拓展業務，提供不同種類的技術支援。</p>
                        </div>

                        <div className='wow fadeInLeft'>
                            <a href="/web-development" className='effect-cta-2 bg-theme-color-2 hover:bg-theme-color-5 text-white hover:text-black'>網頁開發服務簡介</a>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <img src={contactContent1} />
                    </div>
                </div>
            </div>
        </section>
        <section className='py-[70px] lg:pt-[150px] scroll-mt-[90px]' id='getInTouch'>
            <div className='section-container'>
                <div className='flex justify-center flex-col md:flex-row'>
                    <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                        <div className='mb-[40px] w-full'>
                            <h2 className='mb-4 text-3xl text-theme-color-2'>聯絡我們</h2>
                            <span className='text-xl'>如有任何網站開發、設計報價、數碼營銷、SEO優化或其他查詢。</span>
                            <br/>
                            <span className='text-xl'>歡迎直接聯絡我們！</span>
                        </div>
                        <div className='break-words w-full'>
                            <div className="flex">
                                <SocialMediaShare />
                            </div>
                        </div>

                    </div>
                    <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>
                        <Form />
                    </div>
                </div>
            </div>


        </section>
    </div>
  )
}
