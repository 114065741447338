import moment from 'moment';
import React from "react";
import Ads from "./Ads"
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";


export function BlogListCard({
  link,
  img,
  name,
  price,
}) {
  return (
    <Card shadow={false} className="border border-gray-300">
      <a href={link}>
        <CardBody className="p-0">
          <img src={img} alt={img} className="w-full h-[96px] md:h-[120px] lg:h-[180px]" />
          <div className="pb-0 px-4 justify-between mt-4">
            <div>
              <Typography className="mb-2 text-sm" color="blue-gray" variant="h6">
                {name}
              </Typography>
            </div>
            <Typography
              variant="h6"
              className="text-gray-600 mb-2 "
            >
              {price}
            </Typography>
          </div>
        </CardBody>
      </a>
    </Card>
  );
}
export function BlogListSection({ title, data }) {
  return (
    <section className="">
      <div className="mx-auto text-center">
        <Typography variant="h3" className="my-2 text-3xl">
          {title}
        </Typography>
      </div>
      <div className="mx-auto container">
        <div className="grid grid-cols-2 gap-8 xl:grid-cols-4 md:grid-cols-4">
          {data.map(({ mainImage, categories, title, publishedAt }, index) => (
            <React.Fragment key={index}>
              {index % 6 === 0 && index !== 0 ? (
                <div className="w-full">
                  <Ads />
                </div>
              ) : null}
              <BlogListCard
                key={index}
                link={`/mobile/blog/${categories.slug.current}/${title}`}
                img={mainImage.asset.url}
                name={title}
                price={moment(publishedAt).format('MMM DD, YYYY')}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section >
  );
}

export default BlogListSection;