import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";


export function BrandListCard({
  link,
  img,
  name,
}) {
  return (
    <Card shadow={false} className="border border-gray-300">
      <a href={link}>
        <CardBody className="pb-0">
          <img src={img} alt={img} className="w-full" />
          <div className="justify-between mt-4">
            <div>
              <Typography className="mb-2 text-sm" color="blue-gray" variant="h6">
                {name}
              </Typography>
            </div>
          </div>
        </CardBody>
      </a>
    </Card>
  );
}
export function BrandListSection({ deviceType, title, data }) {
  return (
    <section className="px-8">
      <div className="mx-auto text-center">
        <Typography variant="h3" className="my-2 text-3xl">
          {title}
        </Typography>
      </div>
      <div className="mx-auto container">
        <div className="grid grid-cols-2 gap-8 xl:grid-cols-6 md:grid-cols-4">
          {Object.entries(data).map(([brandKey, brandValue], index) => (
            <BrandListCard
              key={index}
              link={`/${deviceType}/${brandKey}`}
              img={`/images/${deviceType}/${brandKey}.jpg`}
              name={brandValue}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default BrandListSection;