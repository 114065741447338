import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import BlockContent from "@sanity/block-content-to-react"
import Ads from "../../components/Ads";
import MetaTag from "../../components/MetaTag";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import PostWidget from "../../components/PostWidget";
import { getPostDetail, getPosts, getRelatedPosts } from '../../service';
import { mobileBrandArray } from "../../components/Data";

export default function MobileBlog() {
  const { category, title } = useParams()
  const [postDetail, setPostDetail] = useState([])
  const [relatedPosts, setRelatedPosts] = useState([])



  var menu = [];
  useEffect(() => {
    getPostDetail(title).then((newPost) => {
      setPostDetail(newPost[0]);
      const postBody = newPost[0].body;
      postBody.map((item, index) => {
        if (item.style === 'h2') {
          menu[index] = item.children[0].text;
        }
      });
    }).then(() => {
      getRelatedPosts(category, title).then((relatedPosts) => {
        setRelatedPosts(relatedPosts);
      });
    });
  }, []);
  let numberOfH2 = 0

  const serializers = {
    types: {
      block(props) {

        switch (props.node.style) {
          case 'h1':
            return <h1 id={props.children} className="text-2xl font-extrabold my-6">{props.children}</h1>
          case 'h2':
            numberOfH2++
            if (numberOfH2 % 2 == 0 && numberOfH2 != 0) {
              return <><Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' /><h2 id={props.children} className="scroll-mt-24 text-xl font-bold text-blue-900 ">{props.children}</h2></>
            } else {
              return <h2 id={props.children} className="scroll-mt-24 text-xl font-bold text-blue-900 ">{props.children}</h2>
            }
          default:
            if (props.children == '') {
              return false
            }
            return <p className="text-base text-black leading-loose">{props.children}<br /></p>
        }
      },
    }
  }
  let landingArray = [
    { name: '手機資訊', landing: `/mobile` },
    { name: '手機評測', landing: `/mobile/blog` },
    { name: mobileBrandArray[category], landing: `/mobile/blog/${category}` },
    { name: title, landing: `/mobile/blog/${category}/${title}` }];

  const pageAlias = 'mobile-blog-detail'
  const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `mobile/blog/${category}/${title}` });

  return (
    <DocumentMeta {...meta}>
      <section className="mt-2 bg-white px-4  pt-4">
        <BreadcrumbsWithIcon landingArray={landingArray} />
      </section>
      <section className="mt-2 bg-white px-4 pt-4">
        <div className="mx-auto container block lg:flex">
          <div>
            {postDetail.mainImage && postDetail.mainImage.asset && (
              <img
                src={postDetail.mainImage.asset.url}
                alt={postDetail.title}
                title={postDetail.title}
                className="my-2"
              />
            )}
            <BlockContent
              blocks={postDetail.body}
              projectId="t7w9vgpb"
              dataset="production"
              serializers={serializers}
              className="break-words leading-10 text-base"
            />
          </div>
          <div className="ml-0 lg:ml-4 flex-none z-20 pl-0 w-full lg:w-80 xl:text-sm">
            <div className="lg:sticky relative top-24 ">
              <Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' />
              <PostWidget deviceType='mobile' data={relatedPosts} />
            </div>
          </div>

        </div>
      </section>
    </DocumentMeta>
  )
}