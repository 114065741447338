import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { RecommendListSection } from "../../components/RecommendListSection";
import { recommendArray } from "../../components/Data";
export function MobileRecommendList() {

    const landingArray = [
        { name: '手機資訊', landing: `/mobile` },
        { name: '手機程式推介', landing: `/mobile/recommend` },
    ];

    const pageAlias = 'mobile-recommend-list'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/mobile/recommend` });

    return (
        <DocumentMeta {...meta}>
            <section className="mt-2 bg-white px-4  pt-4">
                <BreadcrumbsWithIcon landingArray={landingArray} />
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <RecommendListSection data={recommendArray}/>
            </section>
        </DocumentMeta>
    );
}

export default MobileRecommendList;
