import React from "react";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
export function RecommendDetailCard({
  link,
  img,
}) {
  return (
    <Card shadow={false} className="border border-gray-300">
      <a href={link}>
        <CardBody className="pb-0">
          <img src={img} alt={img} className="w-full mb-8 " />
        </CardBody>
      </a>
    </Card>
  );
}
export function RecommendDetailSection({dataKey, data }) {
  return (
    <>
      <section id={dataKey} key={dataKey} className=" mb-16 md:mb-24">
        <div className="mx-auto text-center">
          <Typography variant="h2" className="my-4 text-2xl">
            {dataKey}
          </Typography>
        </div>
        <div className="mx-auto container">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-6">
            {Object.entries(data).map(([feedKey, feedValue]) => (
              <div key={`${dataKey}-${feedKey}`}>
                <RecommendDetailCard
                  key={feedKey}
                  img={feedValue.artworkUrl100}
                  link={feedValue.url}
                  name={feedValue.name}
                />
                <div className="mx-auto text-center">
                  <Typography variant="h3" className="my-2 text-sm">
                    {feedValue.artistName}
                  </Typography>
                </div>
                <div className="mx-auto text-center ">
                  <Typography variant="h3" className="my-2 text-sm text-gray-700 font-normal">
                    {feedValue.name}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default RecommendDetailSection;