import React, { useState, useEffect, useRef } from 'react';


export const SwiperConfig = myRef => {
    var slideNumber = 3
    var mobileSlideNumber = 1
    var slideMargin = 30
    const getDimensions = () => ({
        container_width: myRef.current.offsetWidth,
        container_height: myRef.current.offsetHeight,
        slide_width : (myRef.current.offsetWidth - (slideMargin * (slideNumber - 1))) /slideNumber,
        slide_width_mobile : (myRef.current.offsetWidth) / mobileSlideNumber,
        slideNumber : slideNumber,
        mobileSlideNumber : mobileSlideNumber,
        slide_margin: slideMargin
    })
    
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const handleResize = () => {

        setDimensions(getDimensions())

      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };