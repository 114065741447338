import React from 'react'
import DocumentMeta from 'react-document-meta';
import Slider from '../../components/Carousel/Slider'
import MetaTag from "../../components/MetaTag";
import Form from '../../components/Form'
import SocialMediaShare from '../../components/SocialMediaShare'
import Swiper from '../../components/Swiper'
import { DigitalMarketingSwiperList} from '../../components/Data'
import indexWebDevelopment from '../../images/index-web-development.png'
import indexWebDesign from '../../images/index-web-design.png'
import { ChatBubbleBottomCenterIcon, ClipboardIcon, CubeTransparentIcon } from '@heroicons/react/24/outline'


export default function ServiceHome() {
    const pageAlias = 'service-home'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service` });

    return (
        <DocumentMeta {...meta}>
            <section>
                <Slider />
            </section>

            <section className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] py-[70px]">
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-[40px] mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>專業團隊</span> 網頁開發</h2>
                            <div className='wow fadeInLeft'>
                                <p>我們提供專業的網頁開發服務，了解您的需求，由豐富經驗的網頁開發人員為你服務。</p>
                                <p>不論是網頁的外觀設計、速度，乃至用戶體驗都是我們著重的地方，必定用心開發每一個網頁，為 閣下的生意提供最優質的協助！</p>
                            </div>

                            <div className='wow fadeInUp'>
                                <a href="/service/web-development" className='effect-cta'>了解我們的網頁開發服務</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <img className='mx-auto' src={indexWebDevelopment} alt="img" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="relative bg-white text-theme-blue py-[70px] text-center">
            <div className='section-container mx-auto px-[20px]'>
                <h2 className='mb-[50px]'>What we do best</h2>
                <div className='flex flex-wrap justify-center'>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={seoLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>SEO<br/>marketing</p>
                    </div>

                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={digitalMarketingLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Digital<br/>marketing</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={designLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Logo<br/>design</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={webDesignLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Web<br/>design</p>
                    </div>
                </div>
            </div>
        </section> */}
            <section className="bg-theme-color-5">
                <div className='section-container mx-auto pb-8'>
                    <div className=' flex-col md:flex-row flex items-center justify-between'>
                        <div className='flex-1 md:ml-[30px] flex-col mb-[30px] md:mb-0 wow rotateIn'>
                            <img className='mx-auto' src={indexWebDesign} alt="img" />
                        </div>
                        <div className='flex-1 text-white text-center md:text-left'>
                            <h3 className='text-[40px] mb-[20px] wow fadeInUp'><span className='font-bold text-theme-color-4 '>嶄新設計</span> 獨一無二</h3>
                            <div className='wow fadeInUp'>
                                <p>除了網頁設計外，公司標誌、卡片、廣告、EDM等，都是我們服務範圍。</p>
                                <p>透過獨特嶄的設計，吸引目標客戶的注意，令您公司的形象深深刻在他們的腦海中！</p>
                            </div>
                            <div className='wow bounceInUp'>
                                <a href="/service/design" className='effect-cta'>了解我們的設計服務</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='bg-white py-[70px] '>
                <div className='section-container max-w-[1300px] '>
                    <h2 className='mb-[40px] text-center text-4xl wow fadeIn text-theme-color-2'>為何選擇我們？</h2>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <ChatBubbleBottomCenterIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px]'>專人跟進</h3>
                            <p className='text-white'>我們重視客戶的要求，並有專人跟進及了解每個細節，務求能夠百分百地達到你的期望！</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <CubeTransparentIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px]'>多元化</h3>
                            <p className='text-white'>我們的服務範圍廣泛，對發展網上生意的您而言，不論是設計、網頁開發，還是廣告營銷，我們都一手包辦！</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <ClipboardIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px]'>一站式服務</h3>
                            <p className='text-white'>我們的網頁開發服務，由外觀設計、程式碼開發，至網頁寄存服務都為你妥善處理！</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-[70px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-2'>個案分享</h2>
                <Swiper arrayList={DigitalMarketingSwiperList} />
            </section>
            <section className='py-[70px] scroll-mt-[90px]' id='getInTouch'>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <h2 className='mb-4 text-theme-color-2 text-4xl'>聯絡我們</h2>
                                <span className='text-xl'>如有任何網站開發、設計報價、數碼營銷、SEO優化或其他查詢。</span>
                                <br />
                                <span className='text-xl'>歡迎直接聯絡我們！</span>
                            </div>
                            <div className='break-words w-full'>
                                <div className="flex">
                                    <SocialMediaShare />
                                </div>
                            </div>

                        </div>
                        <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>
                            <Form />
                        </div>
                    </div>
                </div>


            </section>


        </DocumentMeta>

    )
}
