import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import CardList from '../../components/CardList'
import Accordion from '../../components/Accordion'
import { designQuestionArray, designPlanArray, MetaDataList } from '../../components/Data'
import designCover from '../../images/design-cover.jpg'
import designContent1 from '../../images/design-content1.jpg'

import {
    GiftIcon,
    HomeIcon,
    IdentificationIcon,
    LightBulbIcon,
    EnvelopeIcon,
    SpeakerWaveIcon,
} from "@heroicons/react/24/outline";

export default function WebDesign() {
    const pageAlias = 'service-web-design'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/web-design` });
      return (
    <DocumentMeta {...meta}>
        <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
        <img className="absolute opacity-50 inset-0 w-full h-full object-cover -z-10"
                src={designCover}
                />
            <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center font-bold text-theme-color-2 wow fadeIn'>
                <h1 className='mb-8 text-5xl'>設計</h1>
                <p className='leading-[35px] text-lg'>能夠反映出公司風格的設計，往往能令客戶有深刻的印象。我們除了在品牌設計外，還有廣告宣傳設計和排版設計
的相關項目，多方面協助您建立公司的品牌形象。</p>
            </div>
        </section>
        <section className='bg-transparent md:-mt-[500px]'>
            <div className='section-container mx-auto px-[30px]'>
                <CardList planList={designPlanArray}/>
            </div>
        </section>
        <section className='py-[70px]'>
            <div className='section-container mx-auto px-[30px]'>
            <div className='text-center'>
                    <h2 className='mb-[10px] text-4xl'>多元化服務項目</h2>
                </div>
                <div className='flex-col md:flex-row flex items-center'>
                    <div className='mb-[50px] md:hidden'>
                        <img src={designContent1} alt='設計項目' />
                    </div>
                    <div className='w-full md:w-auto flex-1'>
                        <div className='flex mb-[30px] justify-end flex-row-reverse md:flex-row'>
                            <div className='md:text-right md:mr-[20px] ml-[20px] md:ml-0'>
                                <h3 className='text-2xl mb-[10px]'>網頁</h3>
                            </div>
                            <div className='min-w-[40px]'>
                                <HomeIcon className='w-12 mr-8'/>
                            </div>
                        </div>
                        <div className='flex mb-[30px] justify-end flex-row-reverse md:flex-row'>
                            <div className='md:text-right md:mr-[20px] ml-[20px] md:ml-0'>
                                <h3 className='text-2xl mb-[10px]'>廣告</h3>
                            </div>
                            <div className='min-w-[40px]'>
                                <SpeakerWaveIcon className='w-12 mr-8'/>
                            </div>
                        </div>
                        <div className='flex mb-[30px] justify-end flex-row-reverse md:flex-row'>
                            <div className='md:text-right md:mr-[20px] ml-[20px] md:ml-0'>
                                <h3 className='text-2xl mb-[10px]'>商標</h3>
                            </div>
                            <div className='min-w-[40px]'>
                                <LightBulbIcon className='w-12 mr-8'/>
                            </div>
                        </div>
                    </div>
                    <div className='mx-[20px] hidden md:block flex-1'>
                        <img src={designContent1} alt='設計項目' />
                    </div>
                    <div className='flex-1'>
                        <div className='flex mb-[30px]'>
                            <div className='min-w-[40px]'>
                                <EnvelopeIcon className='w-12 ml-8'/>
                            </div>
                            <div className='ml-[20px]'>
                                <h3 className='text-2xl mb-[10px]'>EDM</h3>
                            </div>

                        </div>
                        <div className='flex mb-[30px]'>
                            <div className='min-w-[40px]'>
                                <GiftIcon className='w-12 ml-8'/>
                            </div>
                            <div className='ml-[20px]'>
                                <h3 className='text-2xl mb-[10px]'>圖像</h3>
                            </div>

                        </div>
                        <div className='flex'>
                            <div className='min-w-[40px]'>
                                <IdentificationIcon className='w-12 ml-8'/>
                            </div>
                            <div className='ml-[20px]'>
                                <h3 className='text-2xl mb-[10px]'>卡片</h3>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='bg-theme-color-5 py-[70px]'>
            <div className='section-container max-w-[1300px]'>
            <h2 className='mb-[50px] text-white text-center'>Q&A</h2>
                {designQuestionArray.map(({ title, content }) => (
                    <Accordion key={title} title={title} content={content} />
                ))}
            </div>

        </section>
    </DocumentMeta>
  )
}
