import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import CardList from '../../components/CardList'
import Accordion from '../../components/Accordion'
import { digitalMarketingQuestionArray, digitalMarketingPlanArray, MetaDataList } from '../../components/Data'
import digitalMarketingCover from '../../images/digital-marketing-cover.jpg'
import {
    CurrencyDollarIcon,
    CloudIcon,
    ChartPieIcon,
    ChartBarSquareIcon,
} from "@heroicons/react/24/outline";

export default function DigitalMarketing({ service, description }) {
    const pageAlias = 'service-digital-marketing'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/digital-marketing` });

    return (
        <DocumentMeta {...meta}>
            <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
                <img className="absolute opacity-50 bg-black inset-0 w-full h-full object-cover -z-10"
                    src={digitalMarketingCover}
                />
                <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center text-theme-color-2 font-bold wow fadeIn'>
                    <h1 className='mb-8 text-5xl'>數碼營銷計劃</h1>
                    <p className='leading-[35px] text-lg'>數碼營銷有多種不同方法，透過網上宣傳如SEM、社交媒體廣告等，尋找目標客戶，增加網店銷售額。</p>
                </div>
            </section>
            <section className='bg-transparent md:-mt-[500px]'>
                <div className='section-container mx-auto px-[30px]'>
                    <CardList planList={digitalMarketingPlanArray} />
                </div>
            </section>
            <section className='py-[70px] bg-[#f2f4f7]'>
                <div className='section-container mx-auto px-[30px]'>
                    <div className='grid md:grid-cols-2 gap-[30px]'>
                        <div className='bg-white px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                    <ChartBarSquareIcon className='w-12' />
                                </div>
                                <div className='ml-[20px]'>
                                    <h4 className='text-[20px] md:text-[28px] mb-[5px]'>Google廣告推廣</h4>
                                    <p>用戶搜尋相關主題時，都能找到您的業務資料</p>
                                </div>
                            </div>
                            <ul className='list-disc pl-[20px]'>
                                <li>發展網上生意</li>
                                <li>搜尋排名第一版</li>
                                <li>提升品牌形象</li>
                            </ul>
                        </div>
                        <div className='bg-white px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                    <CurrencyDollarIcon className='w-12' />
                                </div>
                                <div className='ml-[20px]'>
                                    <h4 className='text-[20px] md:text-[28px] mb-[5px]'>社交媒體推廣</h4>
                                    <p>社交平台用戶眾多，發掘您的潛力客戶</p>
                                </div>
                            </div>
                            <ul className='list-disc pl-[20px]'>
                                <li>發展Facebook生意</li>
                                <li>發展Instagram生意</li>
                                <li>每月第三方報告</li>
                            </ul>
                        </div>
                        <div className='bg-white px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                    <ChartBarSquareIcon className='w-12' />
                                </div>
                                <div className='ml-[20px]'>
                                    <h4 className='text-[20px] md:text-[28px] mb-[5px]'>專業分析</h4>
                                    <p>分析您的目標客戶，作出最合適的措施</p>
                                </div>
                            </div>
                            <ul className='list-disc pl-[20px]'>
                                <li>分析使用者習慣</li>
                                <li>分析推廣頻道點擊率</li>
                                <li>廣告針對目標客戶</li>
                            </ul>
                        </div>
                        <div className='bg-white px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                    <ChartPieIcon className='w-12' />
                                </div>
                                <div className='ml-[20px]'>
                                    <h4 className='text-[20px] md:text-[28px] mb-[5px]'>ROI優化</h4>
                                    <p>根據市場數據，選取最有成效的關鍵字</p>
                                </div>
                            </div>
                            <ul className='list-disc pl-[20px]'>
                                <li>分析關鍵字成效</li>
                                <li>靈活調整成本分佈</li>
                                <li>專業技術團隊</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-theme-color-5 py-[70px]'>
                <div className='section-container max-w-[1300px]'>
                    <h2 className='mb-[50px] text-white text-center'>Q&A</h2>
                    {digitalMarketingQuestionArray.map(({ title, content }) => (
                        <Accordion key={title} title={title} content={content} />
                    ))}
                </div>
            </section>
        </DocumentMeta>
    )
}
