import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import CardList from '../../components/CardList'
import Accordion from '../../components/Accordion'
import { webdevelopmentQuestionArray, webDevelopmentPlanArray, MetaDataList } from '../../components/Data'
import webDevelopmentCover from '../../images/web-development-cover.jpg'
import webDevelopmentContent1 from '../../images/web-development-content1.jpg'
import webDevelopmentContent2 from '../../images/web-development-content2.jpg'
import webDevelopmentContent3 from '../../images/web-development-content3.jpg'

export default function WebDevelopment() {
    const pageAlias = 'service-web-development'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/web-development` });

      return (
    <DocumentMeta {...meta}>
        <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
            <img className="absolute opacity-50 bg-black inset-0 w-full h-full object-cover -z-10"
                src={webDevelopmentCover}
                />
            <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center font-bold text-theme-color-2 wow fadeIn'>
                <h1 className='mb-8 text-5xl'>網頁開發</h1>
                <p className='leading-[35px] text-lg'>製作專屬於您的網站，響應式網頁設計令用戶不論以任何電子設備瀏覽網頁都能以最好介面示人。</p>
            </div>
        </section>
        <section className='bg-transparent md:-mt-[500px]'>
            <div className='section-container mx-auto px-[30px]'>
                <CardList planList={webDevelopmentPlanArray}/>
            </div>
        </section>
        <section className='py-[70px]'>
            <div className='section-container mx-auto px-[30px]'>
                <div className='flex-col-reverse md:flex-row flex items-center mb-[30px] md:mb-[0px]'>
                    <div className='flex-1 md:mr-[30px] text-center md:text-left wow fadeInLeft'>
                        <h3 className='font-bold pb-4'>響應式網頁設計</h3>
                        <p className='text-[#527AA8] '>網頁會根據不同的電子設備 (桌面電腦、平板電腦、或智能電話等)的螢幕尺寸而調整顯示介面，提供最好的用戶體驗。</p>
                    </div>
                    <div className='flex-1 max-w-[500px] md:max-w-full mb-[30px] wow fadeInRight'>
                        <img src={webDevelopmentContent1} alt="響應式網頁設計" />
                    </div>
                </div>
                <div className='flex-col md:flex-row flex items-center mb-[30px] md:mb-[0px]'>
                    <div className='flex-1 max-w-[500px] md:max-w-full mb-[30px] wow fadeInLeft'>
                        <img src={webDevelopmentContent2} alt="簡單易用的網頁後台管理系統" />
                    </div>
                    <div className='flex-1 md:ml-[30px] text-center md:text-left wow fadeInRight'>
                        <h3 className='font-bold pb-4 mb-[5px]'>簡單易用的網頁後台管理系統</h3>
                        <p className='text-[#527AA8]'>無需網頁設計的專業知識也可隨時修改和更新網頁內容。</p>
                    </div>

                </div>
                <div className='flex-col-reverse md:flex-row flex items-center mb-[30px] md:mb-[0px]'>
                    <div className='flex-1 md:mr-[30px] text-center md:text-left wow fadeInLeft'>
                        <h3 className='font-bold pb-4 mb-[5px]'>SEO搜索引擎優化</h3>
                        <p className='text-[#527AA8]'>進行SEO搜尋引擎優化，提高網站排名和流量。</p>
                    </div>
                    <div className='flex-1 max-w-[500px] md:max-w-full mb-[30px] wow fadeInRight'>
                        <img src={webDevelopmentContent3} alt="SEO搜索引擎優化" />
                    </div>
                </div>
            </div>
        </section>

        <section className='bg-theme-color-5 py-[70px]'>
            <div className='section-container max-w-[1300px]'>
            <h2 className='mb-[50px] text-white text-center'>Q&A</h2>
                {webdevelopmentQuestionArray.map(({ title, content }) => (
                    <Accordion key={title} title={title} content={content} />
                ))}
            </div>

        </section>
    </DocumentMeta>
  )
}
