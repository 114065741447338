// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your Firebase config here
const firebaseConfig = {
    apiKey: "AIzaSyBLPIaczazStoFZ-p2mF1DhYuArEcoPUhw",
    authDomain: "digitechinone-web.firebaseapp.com",
    projectId: "digitechinone-web",
    storageBucket: "digitechinone-web.appspot.com",
    messagingSenderId: "501834134953",
    appId: "1:501834134953:web:444b0ac425c1bf634d40b8",
    measurementId: "G-8LZG5M8CGX"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
