import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { ProductListSection } from "../../components/ProductListSection";
import { getDeviceListByBrand } from "../../service";
import { tabletBrandArray } from "../../components/Data";
export function TabletProductList() {
    const [deviceListData, setDeviceListData] = useState([]);
    const { brand } = useParams()

    useEffect(() => {
        getDeviceListByBrand('tablet', brand).then((responseData) => {
            if (responseData) {
                setDeviceListData(responseData)
            }
        });
    }, []);

    const landingArray = [
        { name: '平板資訊', landing: `/tablet` },
        { name: tabletBrandArray[brand], landing: `/tablet/${brand}` },
    ];

    const pageAlias = 'tablet-product-list'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/tablet/${brand}` });

    return (
        <DocumentMeta {...meta}>
            <section className="mt-2 bg-white px-4  pt-4">
                <BreadcrumbsWithIcon landingArray={landingArray} />
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <ProductListSection deviceType='tablet' data={deviceListData}/>
            </section>
        </DocumentMeta>
    );
}

export default TabletProductList;
