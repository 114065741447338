import React from "react";
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { BrandListSection } from "../../components/BrandListSection";
import { tabletBrandArray } from "../../components/Data";
export function TabletBrandList() {

    const landingArray = [
        { name: '平板資訊', landing: `/tablet` },
        { name: '平板品牌', landing: `/tablet/brand` },
    ];

    const pageAlias = 'tablet-brand-list'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `tablet/brand` });

    return (
        <DocumentMeta {...meta}>
            <section className="mt-2 bg-white px-4  pt-4">
                <BreadcrumbsWithIcon landingArray={landingArray} />
            </section>
            <section>
                <BrandListSection deviceType='tablet' data={tabletBrandArray}/>
            </section>
        </DocumentMeta>
    );
}

export default TabletBrandList;
