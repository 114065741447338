import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Error from "./pages/error";

import MobileHome from "./pages/mobile/home";
import MobileBrandList from "./pages/mobile/brand-list";
import MobileProductList from "./pages/mobile/product-list";
import MobileProductDetail from "./pages/mobile/product-detail";
import MobileRecommendList from "./pages/mobile/recommend-list";
import MobileRecommendDetail from "./pages/mobile/recommend-detail";
import MobileBlog from "./pages/mobile/blog";
import MobileBlogDetail from "./pages/mobile/blog-detail";

import TabletHome from "./pages/tablet/home";
import TabletBrandList from "./pages/tablet/brand-list";
import TabletProductList from "./pages/tablet/product-list";
import TabletProductDetail from "./pages/tablet/product-detail";

import ServiceHome from "./pages/service/home";
import DigitalMarketing from "./pages/service/digital-marketing";
import Seo from "./pages/service/seo";
import WebDevelopment from './pages/service/web-development';
import WebDesign from "./pages/service/web-design";

import WOW from 'wowjs'
import "./index.css";


function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  });
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<ServiceHome />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />


        <Route path="/mobile" element={<MobileHome />} />
        <Route path="/mobile/brand" element={<MobileBrandList />} />
        <Route path="/mobile/comparison" element={<Home />} />
        <Route path="/mobile/recommend" element={<MobileRecommendList />} />
        <Route path="/mobile/recommend/:recommendType" element={<MobileRecommendDetail />} />
        <Route path="/mobile/:brand" element={<MobileProductList />} />
        <Route path="/mobile/:brand/:deviceId" element={<MobileProductDetail />} />
        <Route path="/mobile/blog" element={<MobileBlog />} />
        <Route path="/mobile/blog/:category/" element={<MobileBlog />} />
        <Route path="/mobile/blog/:category/:title" element={<MobileBlogDetail />} />

        <Route path="/tablet" element={<TabletHome />} />
        <Route path="/tablet" element={<TabletProductList />} />
        <Route path="/tablet/brand" element={<TabletBrandList />} />
        <Route path="/tablet/:brand" element={<TabletProductList />} />
        <Route path="/tablet/:brand/:deviceId" element={<TabletProductDetail />} />

        <Route path="/service" element={<ServiceHome />} />
        <Route path="/service/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/service/seo" element={<Seo />} />
        <Route path="/service/web-development" element={<WebDevelopment />} />
        <Route path="/service/design" element={<WebDesign />} />


      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
