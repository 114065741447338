
import React from "react";
import Ads from "./Ads"
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";


export function ProductListCard({
  link,
  img,
  name,
  price,
}) {
  return (
    <Card shadow={false} className="border border-gray-300">
      <a href={link}>
        <CardBody className="pb-0">
          <img src={img} alt={img} className=" w-full" />
          <div className="justify-between mt-4">
            <div>
              <Typography className="mb-2 text-sm" color="blue-gray" variant="h6">
                {name}
              </Typography>
            </div>
            <Typography
              variant="h6"
              className="text-gray-600 mb-2 "
            >
              {price}
            </Typography>
          </div>
        </CardBody>
      </a>
    </Card>
  );
}
export function ProductListSection({ deviceType, title='', data }) {
  return (
    <section className="">
      <div className="mx-auto text-center">
        <Typography variant="h3" className="my-2 text-3xl">
          {title}
        </Typography>
      </div>
      <div className="mx-auto container">
        <div className="grid  gap-8 grid-cols-2 xl:grid-cols-6 md:grid-cols-4">
          {data.map(({ id, phone_brand, phone_name, price }, index) => (
            <React.Fragment key={index}>
              {index % 12 === 0 && index !== 0 ? (
                <div className="col-span-2 xl:col-span-6 md:col-span-4 text-center">
                  <Ads />
                </div>
              ) : null}
              <ProductListCard
                key={index}
                link={`/${deviceType}/${phone_brand}/${id}`}
                img={`/images/${deviceType}/${id}.jpg`}
                name={phone_name}
                price={price}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section >
  );
}

export default ProductListSection;