import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { getPosts } from '../../service';
import { mobileBrandArray } from "../../components/Data";
import BlogListSection from '../../components/BlogListSection'
export default function MobileBlog() {
  const [posts, setPosts] = useState([])
  const { category } = useParams()

  useEffect(() => {
    getPosts(category).then((newPosts) => {
      setPosts(newPosts);
    });
  }, [category]);

  let landingArray = [
    { name: '手機資訊', landing: `/mobile` },
    { name: '手機評測', landing: `/mobile/blog` },
    { name: (mobileBrandArray[category] == 'undefined') ? '所有' : mobileBrandArray[category],
      landing: (mobileBrandArray[category] == 'undefined') ? '/mobile/blog' : `/mobile/blog/${category}` }];

  const pageAlias = 'mobile-blog'
  const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: (mobileBrandArray[category] == 'undefined') ? `mobile/blog/` : `/mobile/blog/${category}` });

  return (
    <DocumentMeta {...meta}>
      <section className="mt-2 bg-white px-4  pt-4">
        <BreadcrumbsWithIcon landingArray={landingArray} />
      </section>
      <section className="mt-2 bg-white px-4  pt-4">
        <BlogListSection data={posts} />
      </section>
    </DocumentMeta>
  )
}
