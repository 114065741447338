import React from "react";
import {
  Card,
  Typography,
} from "@material-tailwind/react";
import {
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import Ads from './Ads'
import { basicSpecificationArray, monitorSpecificationArray, cameraSpecificationArray, multimediaSpecificationArray, connectivitySpecificationArray } from "./Data";
export function ProductSpecificationSection({ data }) {
  const tableSections = [
    { title: "基本規格", data: basicSpecificationArray },
    { title: "螢幕規格", data: monitorSpecificationArray },
    { title: "相機規格", data: cameraSpecificationArray },
    { title: "多功能媒體規格", data: multimediaSpecificationArray },
    { title: "連接功能規格", data: connectivitySpecificationArray },
  ];
  return (
    <section className="">
      <div className="mx-auto text-center">
        <Typography variant="h3" className="my-2 text-3xl">
          手機規格
        </Typography>
      </div>
      <div className="mx-auto container grid ">
        {tableSections.map((section, index) => (
          <>
            <Card key={section.title} className="h-full w-full overflow-hidden rounded-none">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    <th colSpan={2} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 ">
                      <div className="flex">
                        {section.title !== "" && (
                          <Cog6ToothIcon className="h-6 w-6" strokeWidth={2} />
                        )}
                        <Typography
                          variant="h2"
                          color="blue-gray"
                          className="font-bold leading-none opacity-70 my-auto ml-2 text-base"
                        >
                          {section.title}
                        </Typography>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(section.data).map(([specificationKey, specificationValue], index) => {
                    const isLast = index === Object.keys(section.data).length - 1;
                    const classes = isLast ? "p-2" : "p-2 border-b border-blue-gray-50";
                    return (
                      <tr key={specificationKey}>
                        <td className={classes}>
                          <Typography variant="small" color="blue-gray" className="font-normal my-auto">
                            {specificationValue}
                          </Typography>
                        </td>
                        <td className={`${classes} bg-blue-gray-50/50`}>
                          <Typography variant="small" color="blue-gray" className="font-normal my-auto">
                            {data[specificationKey]}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
            {
              index == 0 &&
              <section className="mt-2 bg-white px-4  pt-4">
                <div className="mx-auto container grid text-center">
                  <Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' />
                </div>
              </section>
            }

          </>
        ))}
      </div>
    </section>
  );
}

export default ProductSpecificationSection;