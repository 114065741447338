import React, { useState } from 'react';
import AccordionIcon from '../images/accordion-svg.svg'
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={isActive ? 'bg-white shadow-[0_2px_6px_0_rgba(177,174,174,0.5)] border-l-[4px] border-l-theme-blue': 'border-[2px] border-b-0 last:border-b-[2px] border-white'}>
    <div className="cursor-pointer py-[20px] px-[30px] text-[1rem] text-white tracking-[1px] relative flex justify-between " onClick={() => setIsActive(!isActive)} >
      <div className={`font-bold pr-[10px] ${isActive ? "text-theme-blue":"text-white"}`}>{title}</div>
      {isActive}
      <img className={isActive ? 'rotate-90 py-[10px] px-[12px] rounded-[50%] bg-theme-blue': ''} src={AccordionIcon} alt=""/>
    </div>
    {isActive && <div className="overflow-hidden py-[20px] px-[30px] pt-0">{content}</div>}
  </div>
    
  );
};

export default Accordion;