import React, { useEffect, useState } from "react";
import DocumentMeta from 'react-document-meta';
import { useParams } from 'react-router-dom';
import MetaTag from "../../components/MetaTag";
import Ads from "../../components/Ads";
import BreadcrumbsWithIcon from "../../components/BreadcrumbsWithIcon";
import { ProductListSection } from "../../components/ProductListSection";
import { ProductSpecificationSection } from "../../components/ProductSpecificationSection";
import { ProductDetailSection } from "../../components/ProductDetailSection";
import { getDeviceDetail, getDeviceListByBrand } from "../../service";
import { tabletBrandArray } from "../../components/Data";
export function TabletProductDetail() {
    const [deviceDetailData, setDeviceDetailData] = useState([]);
    const [deviceListData, setDeviceListData] = useState([]);
    const { brand, deviceId } = useParams()

    useEffect(() => {
        getDeviceDetail('tablet', brand, deviceId).then((responseData) => {
            if (responseData) {
                responseData.price = responseData.price.replace(/<br>.*/, '');
                setDeviceDetailData(responseData)
            }
        });
        getDeviceListByBrand('tablet', brand).then((responseData) => {
            if (responseData) {
                setDeviceListData(responseData)
            }
        });
    }, []);

    const landingArray = [
        { name: '平板資訊', landing: `/tablet` },
        { name: tabletBrandArray[brand], landing: `/tablet/${brand}` },
        { name: deviceDetailData.phone_name, landing: `/tablet/${brand}/${deviceId}` },
    ];

    const pageAlias = 'tablet-product-detail'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/tablet/${brand}/${deviceId}` });

    return (
        <DocumentMeta {...meta}>
            <section className="mt-2 bg-white px-4  pt-4">
                <BreadcrumbsWithIcon landingArray={landingArray} />
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <ProductDetailSection deviceType='tablet' data={deviceDetailData}/>
            </section>
            <section className="mt-2 bg-white px-4 pt-4">
                <div className="mx-auto container grid text-center">
                    <Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' />
                </div>
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <ProductSpecificationSection data={deviceDetailData} />
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <div className="mx-auto container grid text-center">
                    <Ads adsSlotID='4852464592' adsLayout='' adsFormat='auto' />
                </div>
            </section>
            <section className="mt-2 bg-white px-4  pt-4">
                <ProductListSection deviceType='tablet' title='您可能有興趣的手機型號' data={deviceListData}/>
            </section>
        </DocumentMeta>
    );
}

export default TabletProductDetail;
