import {
    FacebookIcon,
    EmailIcon,
    WhatsappIcon
  } from "react-share";
import { commonInfo } from '../components/Data'

export default function SocialMediaShare(title) {
    const icons = [
        {
            landing: 'https://www.facebook.com/digitechinone',
            icon: FacebookIcon,
        },
        {
            landing: 'mailto:' + commonInfo.email,
            icon:EmailIcon,
        },
        {
            landing: "https://api.whatsapp.com/send/?phone=" + commonInfo.phone + "&text&type=phone_number&app_absent=0",
            icon:WhatsappIcon,
        }
    ]

    const iconSize = 32
  return (
    <div className="mx-1 flex">
        {icons.map((item, index) => (
            <a key={index} href={item.landing} target="_blank">
                <item.icon className="mx-1" size={iconSize} round={true} />
            </a>
        ))}
    </div>
  )
}
